import { inject, provide, reactive, ref } from 'vue';

type OnCoordinatesCallback = (coordinates: [number, number]) => void;

type OnContainerCallback = (id: string) => void;

interface UseMap {
  themeId?: number;
  isOpen: boolean;
  setThemeId: (id?: number) => void;
  toggleOpen: () => void;
  onCoordinatesUpdate: (callback: OnCoordinatesCallback) => () => void;
  updateCoordinates: (coordinates: [number, number]) => void;
  onAddContainer: (callback: OnContainerCallback) => () => void;
  addContainer: (id: string) => void;
  onRemoveContainer: (callback: OnContainerCallback) => () => void;
  removeContainer: (id: string) => void;
  onToggle: (callback: OnContainerCallback) => () => void;
  toggle: (id: string) => void;
}

const MapSymbol = Symbol('map-symbol');

export const useMap = (): UseMap => {
  const isOpen = ref<boolean>(false);
  const themeId = ref<number>();

  const coordinatesCallbacks = ref<OnCoordinatesCallback[]>([]);
  const addContainerCallbacks = ref<OnContainerCallback[]>([]);
  const removeContainerCallbacks = ref<OnContainerCallback[]>([]);
  const toggleCallbacks = ref<OnContainerCallback[]>([]);

  const setThemeId = (id?: number) => {
    themeId.value = id;
  };

  const toggleOpen = () => {
    isOpen.value = !isOpen.value;
  };

  const onCoordinatesUpdate = (callback: OnCoordinatesCallback) => {
    coordinatesCallbacks.value.push(callback);

    return () =>
      (coordinatesCallbacks.value = coordinatesCallbacks.value.filter((cb) => cb !== callback));
  };

  const updateCoordinates = (coordinates: [number, number]) => {
    coordinatesCallbacks.value.forEach((callback) => callback(coordinates));
    isOpen.value = true;
  };

  const onAddContainer = (callback: OnContainerCallback) => {
    addContainerCallbacks.value.push(callback);

    return () =>
      (addContainerCallbacks.value = addContainerCallbacks.value.filter((cb) => cb !== callback));
  };

  const addContainer = (id: string) => {
    addContainerCallbacks.value.forEach((callback) => callback(id));
  };

  const onRemoveContainer = (callback: OnContainerCallback) => {
    removeContainerCallbacks.value.push(callback);

    return () =>
      (removeContainerCallbacks.value = removeContainerCallbacks.value.filter(
        (cb) => cb !== callback,
      ));
  };

  const removeContainer = (id: string) => {
    removeContainerCallbacks.value.forEach((callback) => callback(id));
  };

  const onToggle = (callback: OnContainerCallback) => {
    toggleCallbacks.value.push(callback);

    return () => (toggleCallbacks.value = toggleCallbacks.value.filter((cb) => cb !== callback));
  };

  const toggle = (id: string) => {
    toggleCallbacks.value.forEach((callback) => callback(id));
  };

  const map = reactive({
    isOpen,
    themeId,
    setThemeId,
    toggleOpen,
    onCoordinatesUpdate,
    updateCoordinates,
    onAddContainer,
    addContainer,
    onRemoveContainer,
    removeContainer,
    onToggle,
    toggle,
  });

  provide(MapSymbol, map);

  return map;
};

export const useMapContext = (): UseMap => {
  const map = inject<UseMap>(MapSymbol);

  if (!map) {
    throw new Error('Map context can only be used inside of a map.');
  }

  return map;
};
